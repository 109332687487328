import React from 'react';

import './Home.scss';

const Home = () => {
  return (
    <div className="comming-soon">
      <div className="vc-outer">
        <div className="layer"></div>
        <div className="vc-inner">      
          <h1>Coming Soon</h1>
          <p>We're currently working on creating our new website.</p>          
        </div>
        <p className="bottom"><span className="copyright">© 2020 MyProof. All rights reserved.</span></p>
      </div>      
    </div>
  )
}

export default Home;